import { SimpleDropzone } from 'simple-dropzone';
import classnames from 'classnames';
import React, { useEffect, useRef } from 'react'
import logo from './logo.svg';

function initDropzone({
  dropzoneFile,
  onError,
  onDropFiles
}) {
  const dropCtrl = new SimpleDropzone(document, dropzoneFile.current);
  dropCtrl.on('drop', onDropFiles);
  dropCtrl.on('dropstart', () => {
    console.log('start');
  });
  dropCtrl.on('droperror', (err) => {
    onError(err.message);
  }); 
}

export default function FileManager({ onFiles, onError, error, className }) {
  const dropzoneFile = useRef();
  useEffect(() => {
    initDropzone({
      dropzoneFile,
      onError,
      onDropFiles: ({ files }) => {
        onError('');
        onFiles(files);
      }
    });
  }, [onFiles, onError]);

  return (
    <div className={classnames('App-header', className)}>
      <img
        src={logo}
        className='logo scale-up-center'
        alt='logo'
        onClick={() => {dropzoneFile.current.click()}}
      />
      <input type='file' id='dropzoneFile' ref={dropzoneFile} multiple></input>
      <p>Drop 3D model</p>
      <p className='error-message'>{error}</p>
    </div>
  );
}

