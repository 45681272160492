/* eslint-disable no-restricted-globals */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import classnames from 'classnames';

const supportedExtensions = ['gltf', 'glb', 'fbx'];
let webglViewer;
let lastFiles;

function loadWebGLViewer() {
  return new Promise((res) => {
    if (document.querySelector('#webgl-viewer-script')) {
      res();
    }
    const script = document.createElement('script');
    script.id = 'webgl-viewer-script';
    //script.src = 'http://localhost:8080/webgl-viewer.js';
    script.src = 'https://cdn-webgl.fluidconfigure.com/webgl-viewer/stable/webgl-viewer.js';
    script.type = 'text/javascript';
    script.charSet = 'utf-8';
    document.addEventListener('webglViewer', event => {
      res(event.detail.webglViewer)
    });
    document.body.appendChild(script);
  });
}

async function initWebglViewer({ webglViewer, files }) {
  const settings = await getSettingsFile({ files }) || {};
  await webglViewer.init({
    container: '#webgl-viewer-container',
    settings: {
      scene: {
        hdr: "https://cdn-webgl.fluidconfigure.com/webgl-viewer-playground/industrial_building.hdr",
        gamma: true,
        ...(settings.scene || {})
      },
      controls: {
        minDistance: 0.01,
        maxDistance: 1000,
        enableDamping: true,
        dampingFactor: 0.1,
        rotationSpeed: 1,
        ...(settings.controls || {})
      },
      camera: {
        type: "perspective",
        fov: 20,
        near: 0.1,
        far: 1000,
        ...(settings.camera || {})
      },
      lights: []
    }
  });
}

function getFileURL({ files, file }) {
  const [fileKey, blob] = Array.from(files).find(([key]) => {
    return key.endsWith(file)
  }) || [];
  if (blob) {
    return URL.createObjectURL(blob);
  } 
}

function getMainModelFileURL(files) {
  const modelExtension = supportedExtensions.find((extension) => {
    return getFileURL({ files, file: `.${extension}` })
  });
  if (modelExtension) {
    return {
      modelURL: getFileURL({ files, file: modelExtension }),
      modelExtension
    };
  }
}

async function getSettingsFile({ files }) {
  const jsonFile = Array.from(files).find(([key]) => key.endsWith('.json'));
  const hdr = getFileURL({ files, file: '.hdr'});
  if (jsonFile) {
    const jsonContent = await jsonFile[1].text();
    let settings = JSON.parse(jsonContent);
    if (settings.materialOverrides) {
      Object
        .entries(settings.materialOverrides)
        .forEach(([target, matProps]) => {
          Object.entries(matProps).forEach(([matProp, mapValue]) => {
            if (matProp.endsWith('map') || matProp.endsWith('Map')) {
              if (Array.isArray(mapValue)) {
                matProps[matProp] = mapValue.map((path) => getFileURL({ files, file: path}));
              } else {
                matProps[matProp] = getFileURL({ files, file: mapValue})
              }
            }
          });
      });
    }
    if (hdr) {
      settings.scene = {
        ...settings.scene,
        hdr
      }
    }
    return settings;
  }
  if (hdr) {
    return {
      scene: {
        hdr
      }
    };
  }
}

async function addModelFiles({ files, webglViewer, onError}) {
  const { modelURL, modelExtension } = getMainModelFileURL(files) || {};
  if (!modelURL) {
    onError('No .gltf, .glb or .fbx asset found.');
    return;
  }
  try {
    const loadedModel = await webglViewer.loadModel({
      url: modelURL,
      modelExtension: modelExtension,
      assetURLModifier: (url) => {
        const normalizedURL = url
          .replace(`blob:${location.origin}`, '')
          .replace('/', '');
        return getFileURL({ files, file:  normalizedURL}) || url;
      }
    });
    webglViewer.allowCanvasRendering(false);
    await webglViewer.addModel({
      id: 'main',
      model: loadedModel
    });
    const settings = await getSettingsFile({ files });
    if (settings) {
      const promises = Object
        .entries(settings.materialOverrides || {})
        .map(([target, matProps]) => {
          return webglViewer.applyCustomization([{
            target,
            customization: {
              materialProperties: matProps
            }
          }]);
      });
      await Promise.all(promises);
    }
    webglViewer.allowCanvasRendering(true);
    await webglViewer.updateCamera({
      position: {
        x: loadedModel.center.x + loadedModel.size.x * 1.5,
        y: loadedModel.center.y + loadedModel.size.y * 0.7,
        z: loadedModel.center.z + loadedModel.size.z * 3.5,
      }
    });
  } catch (err) {
    onError(err.message);
  }
  URL.revokeObjectURL(modelURL)
}

export default function Viewer ({ files, onError, className }) {
  const [showSpinner, shouldShowSpinner] = useState(false);
  useEffect(async () => {
    if (!webglViewer && files) {
      webglViewer = await loadWebGLViewer();
      await initWebglViewer({ webglViewer, files });
    }
    if (webglViewer && lastFiles !== files) {
      lastFiles = files;
      await webglViewer.removeModel('main');
      shouldShowSpinner(true);
      await addModelFiles({ files, webglViewer, onError }); 
      shouldShowSpinner(false);
    }
  }, [files])

  return (
    <div className={className}>
      <div id='loading-bar-spinner' className={classnames('spinner', {invisible: !showSpinner })}>
        <div className='spinner-icon'></div>
      </div>
      <div  id='webgl-viewer-container'/>
    </div>
  );
}