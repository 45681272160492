import React, { useState } from 'react'
import classnames from 'classnames';
import Viewer from './Viewer';
import FileManager from './FileManager';
import './App.css';

function App() {
  const [ files, onFiles ] = useState();
  const [ error, onError ] = useState();
  const viewerClassName = classnames({
    invisible: !files || error
  });
  const fileManagerClassName = classnames({
    invisible: files && !error
  });
  return (
    <div className='App'>
      <Viewer
        className={viewerClassName}
        files={files}
        onError={onError}
      />
      <FileManager
        className={fileManagerClassName}
        onFiles={onFiles}
        onError={onError}
        error={error}
      />
    </div>
  );
}

export default App;
